import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/style/reset.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import vuescroll from 'vuescroll';
import scroll from 'vue-seamless-scroll'
// 添加fastclick移除移动端点击延迟
import FastClick from 'fastclick'
// 通过 Vue.use 全局注册
// 注册完成后，在模板中可以通过<van-button>或<VanButton>标签来使用按钮组件
import { NavBar, Loading, Overlay, Checkbox, Toast, Tabbar, TabbarItem, Icon, Button } from 'vant'
// 调试库
import VConsole from 'vconsole'

// 测试环境展示调试信息
if (process.env.NODE_ENV === 'development') {
  let vConsole = new VConsole()
  Vue.use(vConsole)
}

// 注册vant组件
Vue.use(NavBar)
Vue.use(Loading)
Vue.use(Overlay)
Vue.use(Checkbox)
Vue.use(Toast)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Icon)
Vue.use(Button)

Vue.use(scroll)
// 在这里设置全局配置
Vue.use(vuescroll, {
  ops: {
    mode: 'native',
    bar: {
      showDelay: 500,
      onlyShowBarOnScroll: false,//是否只有滚动的时候才显示滚动条
      keepShow: false,
      background: '#1AB063',
      opacity: 0.2,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: false,
      size: '6px',
      disable: false,
      'overflow-x': 'hidden',
    }
  }, // 在这里设置全局默认配置
  name: 'vueScroll' // 在这里自定义组件名字，默认是vueScroll
});

Vue.prototype.axios = axios
Vue.use(ElementUI)
Vue.config.productionTip = false

// FastClick的iOS点击穿透解决方案
FastClick.prototype.focus = (targetElement) => {
  if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
    const length = targetElement.value.length
    targetElement.focus()
    targetElement.setSelectionRange(length, length)
  } else {
    targetElement.focus()
  }
}
FastClick.attach(document.body)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
