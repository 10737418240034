<template>
  <div id="app">
    <transition>
      <router-view></router-view>
    </transition>

    <Tabbar v-if="$route?.meta?.showTabbar" />
  </div>
</template>
<script>
import Tabbar from "@/components/Tabbar.vue";
export default {
  components: {
    Tabbar,
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
